import moment from "moment"

export type RYMEpisode = {
  title: string | null
  teacher: string | null
  description: string | null
  mp3: string | null
  teaching_series: {
    title: string | null
    image: string | null
    url: string | null
  }
  descriptor: string | null
}

export type RYMDay = {
  date: string
  episode: RYMEpisode
  image: string | null
  // "resources": [],
}

export type RYMInfo = {
  date: string | null
  teacher: string | null
  title: string | null
  teaching_series: {
    image: string | null
    title: string | null
    url: string | null
  }
  descriptor: string | null
}

export type CampaignDateRange = {
  starts: string
  ends: string
}

export type CampaignProduct = {
  name: string
  image: {
    three_dimensional: string | null
    flat: string | null
  }
}

export type Campaign = {
  id: string
  slug: string
  title: string | null
  brand: string | null
  call_to_action: string | null
  featured: boolean | null
  featured_international: boolean | null
  ad: string | null
  dates: Array<CampaignDateRange>
  offer: {
    call_to_action: string | null
    ad: string | null
    products: Array<CampaignProduct>
    image: string | null
  }
}

export const dateLabel = function (day: string, isToday: boolean) {
  const thisDate = moment(day, "YYYY/MM/DD")

  if (isToday) return "Today's Edition"

  return thisDate.format("MMM D, YYYY") + " Edition"
}

export const formatDate = function (stamp: string) {
  return moment(stamp, "YYYY/MM/DD").format("MMM D, YYYY")
}

export const getDayOfWeek = function (stamp: string) {
  return moment(stamp, "YYYY/MM/DD").format("ddd")
}

export const getDescriptor = function (episode: RYMEpisode) {
  return episode.descriptor
    ? episode.descriptor
    : "A Broadcast with " + episode.teacher
}

export const getAdImage = function (campaign: Campaign) {
  const offer = campaign.offer
  const product = offer.products[0]
  const img = offer.image
    ? offer.image
    : product.image
      ? product.image.three_dimensional || product.image.flat
      : ""

  return !img ? "" : getImgIX(img) + "?fit=max&w=300&h=300"
}

const getImgIX = function (href: string) {
  return href.replace(
    "https://d2ttzf2z28f6tb.cloudfront.net",
    "https://ligonier.imgix.net",
  )
}

export const getCoverImage = function (day: RYMDay) {
  if (!day) {
    return "https://renewingyourmind.org/images/covers/default.jpg"
  } else if (day.image) {
    // RYM day has an image itself
    return getImgIX(day.image)
  } else if (day?.episode?.teaching_series?.image) {
    // remote cover
    return getImgIX(day.episode.teaching_series.image)
  } else if (day?.episode?.teacher?.toLowerCase() == "r.c. sproul") {
    // rc cover
    return "https://renewingyourmind.org/images/covers/rc.jpg"
  } else {
    // default palm branch cover
    return "https://renewingyourmind.org/images/covers/default.jpg"
  }
}

export const getLiveDateRange = function (dates: Array<CampaignDateRange>) {
  const now = new Date()
  let d = 0

  const inRange = dates.some(function (range, r) {
    const starts = new Date(range.starts)
    const ends = new Date(range.ends)
    const active = now >= starts && now <= ends

    if (active === true) {
      d = r
    }

    return active
  })

  return inRange ? dates[d] : undefined
}

export const slugify = (s: string) =>
  s
    .toString()
    .toLowerCase()
    // eslint-disable-next-line no-useless-escape
    .replace(/\s+/g, "-") // Replace spaces with -
    // eslint-disable-next-line no-useless-escape
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    // eslint-disable-next-line no-useless-escape
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .trim()
